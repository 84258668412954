﻿$device-small-mobile: 425px;
$device-mobile: 600px;
$device-tablet: 900px;
$device-desktop: 1200px;

$small-device: 576px;
$medium-device: 768px;
$large-device: 992px;
$extra-large-device: $device-desktop;

@mixin respond-min( $width ) {
    @media only screen and ( min-width: $width ) {
        @content;
    }
}