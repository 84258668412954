﻿$button-secondary-normal-background: #5DCAE2;
$button-secondary-hover-background: #202C54;
$button-min-width: 204px;

$path-images: '/assets/images/icons';

// -------------------------------------------------------------------------------------------------------
// FONT FAMILIES
// -------------------------------------------------------------------------------------------------------

$font-family-1: 'Poppins','san-sarif';
$font-family-2: 'Roboto';

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Body

$body-font-family: $font-family-2;
$body-font-weight: $font-weight-normal;
$body-font-size: 16px;
$body-line-height: 24px;
$body-font-size-mobile: 16px;
$body-line-height-mobile: 24px;

// Small body

$small-body-font-family: $font-family-2;
$small-body-font-weight: $font-weight-normal;
$small-body-font-size: 14px;
$small-body-line-height: 20px;


// Label

$label-font-family: $font-family-2;
$label-font-weight: $font-weight-bold;
$label-font-size: 18px;
$label-line-height: 24px;

$air-force-blue: #5DCAE2;
$army-red: #E90041;
$navy-blue: #202C54;