﻿@import "variables.scss";
@import "media.scss";

@mixin button-icon($image-default-url, $image-active-url) {
    position: relative;
    padding-left: 3em;

    &:before {
        position: absolute;
        top: 8px;
        left: 16px;
        display: block;
        height: 23px;
        width: 23px;
        background: url($image-default-url) no-repeat center center;
        background-size: contain;
        content: '';
    }

    &:hover:before {
        background-image: url($image-active-url)
    }
}
// -------------------------------------------------------------------------------------------------------
// MIXINS: GLOBAL
// -------------------------------------------------------------------------------------------------------

@mixin image-icon($url, $height, $width) {
    display: block;
    height: $height;
    width: $width;
    background: url($url) 50% 50% no-repeat;
    background-size: contain;
    z-index: 1;
}

// -------------------------------------------------------------------------------------------------------
// MIXINS: TYPOGRAPHY
// -------------------------------------------------------------------------------------------------------

@mixin amount-raised() {
    font-family: $intro-font-family;
    font-weight: $font-weight-bold;
    font-size: $intro-font-size-mobile;
    line-height: $intro-line-height-mobile;
    color: $highlight-color;

    @include respond-min($medium-device) {
        font-size: $intro-font-size;
        line-height: $intro-line-height;
    }
}

@mixin required-asterisk {
    @content;
    content: '*';
    color: $validation-error;
}

// -------------------------------------------------------------------------------------------------------
// MIXINS: BUTTONS
// -------------------------------------------------------------------------------------------------------

@mixin btn-style($normal-bg-color, $normal-font-color, $hover-bg-color, $hover-border-color, $hover-font-color, $active-font-color, $active-bg-color, $active-border-color, $border-color, $padding, $border-width: 2px, $border-radius: 12px, $min-width: 224px) {
    position: relative;
    display: block;
    padding: $padding;
    width: auto;
    border: solid $border-width $border-color;
    border-radius: $border-radius;
    background-color: $normal-bg-color;
    min-width: $min-width;
    transition: 0.4s;
    cursor: pointer;
    -webkit-appearance: none;

    &, span {
        color: $normal-font-color;
        text-align: center;
        text-decoration: none;
        line-height: normal;
    }

    &:hover:not([disabled]) {
        background-color: $hover-bg-color;
        border-color: $hover-border-color;


        &, span {
            color: $hover-font-color;
            text-decoration: none;
        }
    }

    &:active {
        background-color: $active-bg-color;
        border-color: $active-border-color;
        color: $active-font-color;
    }

    &:focus {
        outline: none;
        box-shadow: $btn-focus-style;
    }

    &:disabled,
    &[disabled] {
        background-color: $btn-disabled-bg-color;
        color: $btn-disabled-font-color;
        border-color: $btn-disabled-border-color;
        cursor: not-allowed;

        &:hover {
            text-decoration: none;
        }
    }
}

@mixin btn-font-style($btn-font-family, $btn-font-weight, $btn-font-size) {
    &, span {
        font-family: $btn-font-family;
        font-weight: $btn-font-weight;
        font-size: $btn-font-size;
        //@include respond-min($small-device) {
        //    font-size: $btn-font-size-tablet;
        //}
        //@include respond-min($large-device) {
        //    font-size: $btn-font-size-desktop;
        //}
    }
}

@mixin shadow-style() {
    box-shadow: 0 6px 10px rgba(0,0,0,0.16);
}

@mixin btn-icon-left($url, $height, $width, $left) {
    position: relative;
    padding-left: 56px;

    &:before {
        position: absolute;
        display: block;
        top: calc(50% - (#{$height} / 2));
        left: $left;
        background-size: contain;
        content: '';
        margin-right: 1em;
        vertical-align: middle;
        @include image-icon($url, $height, $width)
    }
}

@mixin btn-icon-right($url, $height, $width, $right) {
    position: relative;
    padding-right: 65px;

    &:after {
        position: absolute;
        top: calc(50% - (#{$height} / 2));
        right: $right;
        background-size: contain;
        content: '';
        display: inline-block;
        vertical-align: middle;
        @include image-icon($url, $height, $width)
    }
}
