@import "media.scss";

.featured-carousel {
    padding: 1em 2em;
    width: 100%;
}

.carousel-item {
    flex: 1 0 auto;
    margin: 0 auto;
    padding: 0;
    max-width: 400px;

    @include respond-min($device-mobile) {
        max-width: 180px;
    }

    @include respond-min($device-tablet) {
        max-width: 220px;
    }

    .carousel-image {
        width: 100%;

        & > img {
            border: 1px solid #E6E6E6;
        }
    }

    .carousel-name {
        padding: 5% 2%;
        position: relative;
        left: 10%;
        bottom: 25px;
        width: 80%;
        color: #fff;
        background-color: #202c54;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        text-align: center;
    }
}

// override default styling
.owl-carousel {
    .owl-nav {
        button {
            &.owl-next,
            &.owl-prev {
                margin: 0;
                position: absolute;
                top: calc(50% - 60px);

                &:hover {
                    background-color: #202C54;
                }
            }

            &.owl-prev {
                background: #5dcae2 url("/assets/content/themes/skin/images/icons/carousel/arrow.svg") 50% 50% no-repeat;
                display: block;
                height: 45px;
                left: 20px;
                width: 45px;
                z-index: 1;
            }

            &.owl-next {
                background: #5dcae2 url("/assets/content/themes/skin/images/icons/carousel/arrow.svg") 50% 50% no-repeat;
                display: block;
                height: 45px;
                right: 20px;
                width: 45px;
                z-index: 1;
                transform: scale(-1);
            }
        }
    }
}



