@import "variables.scss";
@import "media.scss";
@import "mixins.scss";
@import "colours.scss";

.c-site-content_fund {
    .c-fund-header {
        .c-fund-information__details {
            .c-fund-information__photo {
                @include respond-min($large-device) {
                    flex: 1 50%;
                    max-width: 390px;
                }
            }

            .c-fund-information__content {
                @include respond-min($large-device) {
                    flex: 0 1 100%;
                }

                @include respond-min($device-desktop) {
                    flex: 0 1 560px;
                }
            }

            .c-fund-information__donations {
                @include respond-min($device-mobile) {
                    flex: 1 auto;
                    max-width: 250px;
                }

                .c-donations-summary_wrap {
                    margin: 0 auto;
                }
            }
        }
    }
}

