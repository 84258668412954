form {

    #your-details, #payment-buttons, #payment-form, #complete-my-donation {
        display: none;
    }

    #paymentMethodSelector {
        overflow: hidden;
        margin-bottom: 1.5em;
    }

    #paypal-button {
        max-width: 350px;
    }

    #Form_Address_SelectList {
        margin-bottom: 1em;
    }

    fieldset {
        &.e-fieldset {
            .o-form-field-group {
                #card-image {
                    position: absolute;
                    top: 0.35em;
                    right: 0.3em;
                    width: 44px;
                    height: 28px;
                    background-color: white;
                    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/346994/card_sprite.png);
                    background-size: 86px 458px;
                    background-position: -100px 0;
                    background-repeat: no-repeat;
                    margin-bottom: 1em;

                    &.visa {
                        background-position: 0 -398px;
                    }

                    &.master-card {
                        background-position: 0 -281px;
                    }

                    &.american-express {
                        background-position: 0 -370px;
                    }

                    &.discover {
                        background-position: 0 -163px;
                    }

                    &.maestro {
                        background-position: 0 -251px;
                    }

                    &.jcb {
                        background-position: 0 -221px;
                    }

                    &.diners-club {
                        background-position: 0 -133px;
                    }
                }
            }
        }
    }
}


.cr-page {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 1240px;
}

@media (min-width: 1200px) {
    .cr-page {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}


.textarea-wrapper {
    position: relative;
    max-width: 350px;

    .characters-remaining {
        font-size: 13px;
        color: #999;
        position: absolute;
        bottom: 0;
        right: 5px;
    }

    textarea {
        min-height: 50px;
        margin-bottom: 15px;
    }
}


.e-form-input + .field-validation-error {
    color: red;
}

#donation_address_wrapper, .manualSelector {
    a {
        text-decoration: underline;
        cursor:pointer;

        &:hover {
            text-decoration: none;
        }
    }
}

/*.o-form-field-group__textarea-wrapper + .field-validation-error {
    margin-top: -8px;
}*/