@import "media.scss";

.jumbotron {
    position: relative;
    height: auto;

    @include respond-min($device-mobile) {
        height: 420px;
    }

    &:after {
        position: absolute;
        content: url(/assets/content/themes/skin/images/logos/threestripes.svg);
        left: calc(50% - 15px);
        bottom: -27px;
    }

    .wrapper {
        position: relative;
        padding: 0;
        z-index: 10;

        @include respond-min($device-tablet) {
            padding: inherit;
        }
    }

    .jumbotron-content {
        padding: 28px 14px 18px;
        background-color: #202c54;

        @include respond-min($device-mobile) {
            margin: 0;
            padding: 18px 28px;
            width: 100%;
        }

        h1 {
            font-size: 36px;
            color: #ffffff;

            @include respond-min($device-mobile) {
                font-size: 56px;
            }
        }

        h2, h3, h4 {
            color: #ffffff;
        }

        p {
            font-family: 'Roboto';
            font-size: 21px;
            font-weight: 400;
            color: #ffffff;

            @include respond-min($device-mobile) {
                font-size: 24px;
            }
        }

        .cta {
            display: flex;
            flex-flow: row wrap;
            width: 100%;

            @include respond-min($device-mobile) {
                position: absolute;
                left: 20px;
                bottom: -19px;
            }

            & > .button {
                font-size: 18px;
                text-transform: uppercase;
                text-decoration: none;
            }
        }

        @include respond-min($device-mobile) {
            position: absolute;
            top: 80px;
            width: 560px;
        }

        @include respond-min($device-desktop) {
            top: 80px;
            width: 630px;
        }
    }

    .jumbotron-image {
        height: 220px;
        width: 100%;
        background-position: right center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;

        @include respond-min($device-mobile) {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }

        @include respond-min($device-mobile) {
            background-position: center;
        }
    }
}
