@import "media.scss";

.information-panel {
    position: relative;
    margin: 0 auto 2.5em;
    padding: 2em 30px;
    max-width: 850px;
    background-color: #E6E6E6;
    color: #1D1D1B;

    @include respond-min($device-desktop) {
        padding: 2em 50px;
    }

    .close-button {
        margin: 0;
        position: absolute;
        display: block;
        right: 10px;
        top: 10px;
        height: 20px;
        width: 20px;
        background: url(/assets/images/icons/close-icon.svg) 50% 50% no-repeat;
        background-size: contain;
    }
}
