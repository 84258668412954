@import "variables.scss";
@import "media.scss";
@import "mixins.scss";

.button-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
}

.button {
    display: inline-block;
    margin: 1em 0 0;
    padding: .4em 1em;
    width: 100%;
    border: 0;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Poppins','san-sarif';
    text-align: center;
    text-decoration: none;

    @include respond-min($device-mobile) {
        width: auto;
        margin: 1em 1em 0 0;
    }

    &.button-primary {
        background-color: #E90041;
        color: #ffffff;
        text-transform: uppercase;

        &:hover {
            background-color: $button-secondary-hover-background;
        }
    }

    &.button-secondary {
        background-color: $button-secondary-normal-background;
        color: #ffffff;
        text-transform: uppercase;



        &:hover {
            background-color: $button-secondary-hover-background;
        }
    }

    &.button-secondary-box {
        background-color: #ffffff;
        color: #202C54;
        text-transform: uppercase;

        &:hover {
            background-color: $button-secondary-hover-background;
            color: #ffffff;
        }
    }

    &.button-ghost {
        border: 2px solid #202C54;
        border-radius: 6px;
        color: #202C54;
        font-family: 'Roboto';

        &:hover {
            background-color: #202C54;
            color: #ffffff;
        }
    }

    &.button-edit {
        @include button-icon("/assets/Images/Icons/Edit/update-icon.svg", "/assets/Images/Icons/Edit/update-icon-active.svg");
    }

    &.button-photo {
        @include button-icon("/assets/Images/Icons/Edit/photo-icon.svg", "/assets/Images/Icons/Edit/photo-icon-active.svg");
    }

    &.button-donation {
        @include button-icon("/assets/Images/Icons/Edit/donation-icon.svg", "/assets/Images/Icons/Edit/donation-icon-active.svg");
    }

    &.button-video {
        @include button-icon("/assets/Images/Icons/Edit/video-icon.svg", "/assets/Images/Icons/Edit/video-icon-active.svg");
    }
}

.e-button_twitter {
    &::before {
        margin-left:.8em;
    }
}
