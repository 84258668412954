.input-container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 12pt;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default checkbox */
    input[type=checkbox] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom checkbox */
    .checkmark {
        margin: 0;
        margin-right: 10px;
        height: 25px;
        width: 25px;
        background-color: #fff;
        border: solid 1px #000;

        /* Create the checkmark/indicator (hidden when not checked) */
        &:after {
          content: "";
          position: relative;
          display: none;
          left: 8px;
          top: 1px;
          width: 8px;
          height: 18px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
    }


    /* Show the checkmark when checked */
    input:hover ~ .checkmark:after,
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    & input:checked ~ .checkmark {
        background-color: #51a33d;
        border-color: #51a33d;
    }
}