@import "media.scss";

.general ol li {
    font-size: 24px;
}

header {
    position: fixed;
    top: 0;
    height: 73px;
    width: 100%;
    background: rgb(255,255,255);
    box-shadow: 0 2px 7px 2px #00000015;
    z-index: 1000;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);

    @include respond-min($device-tablet) {
        height: 100px;
    }

    .wrapper {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-content: center;

        @include respond-min($device-tablet) {
            align-items: flex-end;
        }
    }

    .header-logo-box {
        position: absolute;
        left: 14px;
        top: 0;
        height: 89px;
        width: 140px;
        background: white;
        box-shadow: 0 2px 7px 2px #00000017;
        padding: 13px 5px;
        z-index: 20;

        @include respond-min($device-tablet) {
            left: 28px;
            height: 123px;
            width: 192px;
        }

        @include respond-min($device-desktop) {
            left: 0;
        }

        .logo-brand {
            background-image: url(/assets/content/themes/skin/images/logos/logo.svg);
            background-size: contain;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            width: 100%;
            height: 40px;

            @include respond-min($device-tablet) {
                height: 60px;
            }
        }

        .logo-strap-line {
            background-image: url(/assets/content/themes/skin/images/logos/herofunds.png);
            background-size: contain;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            width: 100%;
            height: 20px;
            margin-top: 0.2em;

            @include respond-min($device-tablet) {
                height: 30px;
            }
        }
    }
}

main {
    margin-top: 73px;
    padding-bottom: 2em;

    @include respond-min($device-desktop) {
        margin-top: 100px;
    }
}

footer {
    padding: 2em 0;
}

p {
    margin-bottom: 1em;
}

.wrapper {
    margin: 0 auto;
    padding: 0 14px;
    max-width: 1240px;

    @include respond-min($device-tablet) {
        padding: 0 28px;
    }

    @include respond-min($device-desktop) {
        padding: 0;
    }

    .wrapper-header {
        min-height: 73px;
        padding: 30px 0 0;

        @include respond-min($device-desktop) {
            padding: 30px 0 0;
        }
    }
}

.t-recaptcha {
    font-size: 0.8em;
    display: flex;

    span {
        margin: 0 auto;
    }
}

input.is-disabled {
    transition: background 250ms;
    background-color: #ccc !important;
}

.is-hidden {
    display: none;
}

.hidden {
    display: none !important;
}

.noflex {
    display: block !important;
}

.form-width {
    max-width: 800px;
}


.c-fund-information__photo {
    margin-bottom: .8em;
}

.c-fund-content__donations {

    @include respond-min($device-desktop) {
        margin-bottom: 0;
    }

    @include respond-min($device-mobile) {
        margin-bottom: 2em;
    }
}

.c-fund-information__donations .share.addthis {
    margin-bottom: 20px;

    @include respond-min($device-mobile) {
        margin-bottom: 0;
    }
}

.introduction {
    font-size: 1.2em;
}

.c-page-content__sub-title{
    margin-bottom:1.5em;
}