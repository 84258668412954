﻿// -------------------------------------------------------------------------------------------------------
// COLOURS
// -------------------------------------------------------------------------------------------------------

// Colours: Brand

$brand-1: #FF8400;
$brand-2: #7E3F8F;
$brand-3: #7F8AC4;
$brand-4: #51A33D;

// Colours: Tints

$brand-1-light: #FFF5EB;
$brand-1-dark: #E57700;

// Colours: Neutrals

$black: #373737;
$grey-dark: #6F6F6F;
$grey: #DBDBDB;
$grey-light: #F3F3F3;
$white: #FFFFFF;
$transparent: transparent;

// Colours: Validation

$green: #4CAF50;
$green-light: #EDF7ED;
$red: #A8141C;
$red-light: #FEF5F5;

// Validation states

$validation-success: $green;
$validation-success-bg: $green-light;
$validation-error: $red;
$validation-error-bg: $red-light;

 // Text colours

$h1-color: $white;
$h2-color: $black;
$h3-color: $black;
$h4-color: $black;
$intro-color: $black;
$highlight-color: $brand-1-dark;
$body-color: $black;
$small-body-color: $grey-dark;
$label-color: $black;


