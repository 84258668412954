@import "variables.scss";
@import "media.scss";
@import "mixins.scss";
@import "colours.scss";

//candle display on fund page
.candles-container {
    z-index: 0;
    position:relative;

    .candles {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        @include respond-min($device-desktop) {
            flex: 1;
        }

        .rte {
            width: 100%;
            padding: 2em 0 1em 0;
        }

        .title {
            align-items: center;
            display: flex;
            flex: 100%;

            &:before {
                margin-right: 16px;
                @include image-icon('#{$path-images}/fund-candle.svg', 40px, 25px);
                margin-bottom: 2px;
                content: '';
            }
        }


        .title, .c-donations__pager, .action-buttons {
            justify-content: center;
            flex: 100%;
            text-align: center;
        }

        .rte {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .list {
            display: block;

            @include respond-min($device-desktop) {
                display: flex;
                justify-content: space-around;
                height: 196px;
                flex: 100%;
            }

            li {
                position: relative;
                flex: 1 50%;
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                flex-flow: row nowrap;
                max-width: 640px;
                align-items: center;
                margin: 0 auto;

                @include respond-min($device-desktop) {
                    max-width: 512px;
                }

                &:not(:last-of-type) {
                    border-bottom: none;
                    padding-bottom: 24px;
                }

                .candle-image {
                    background-color: transparent;
                    width: 88px;
                    height: 150px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 24px;
                    //position: relative; // not required here
                    min-width: 88px;
                    //z-index: 1; // this stop the flame from appearing from behind

                    &.lit {
                        &::before {
                            content: ' ';
                            width: 88px;
                            height: 124px;
                            background-color: transparent;
                            background-image: url('#{$path-images}/candle-lit.gif');
                            background-size: contain;
                            display: block;
                            background-size: 64px;
                            background-repeat: no-repeat;
                            background-position: center -6px;
                            position: absolute;
                            z-index: -1;
                        }
                    }

                    &.extinguished::before {
                        content: ' ';
                        width: 88px;
                        height: 124px;
                        background-image: url('#{$path-images}/candle-extinguished.gif');
                        background-size: contain;
                        display: block;
                        background-size: 64px;
                        background-repeat: no-repeat;
                        background-position: center -4px;
                    }
                }

                .candle-msg {
                    display: flex;
                    flex-direction: column;

                    div:first-of-type {
                        align-self: flex-start;
                    }

                    div:last-of-type {
                        display: flex;
                        flex-direction: column;
                        align-self: flex-start;

                        > *:last-child {
                            display: block;
                            font-size: 14px;
                            font-weight: normal;
                            color: $grey-dark;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }

        .c-donations__pager, .c-my-donations__pager {
            order: unset;
            margin-bottom: 12px;
            order: 2;
        }

        .action-buttons {
            order: 3;
        }
    }
}

.fund-theme {
    background-position: center;
    background-repeat: repeat;
    background-size: auto;
}

.fund-theme-select, .fund-candle-select {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    margin-top: 20px;

    .fund-theme-select-option, .fund-candle-select-option {
        position: relative;
        margin: 0 1em 1em 0;
        flex: 372px;
        height: 202px;
        width: 100%;

        input[type=radio] {
            display: none;

            &:checked {
                & + label:before {
                    display: block;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    box-shadow: inset 0 0 0 8px rgba(255, 255, 255, 0.5);
                    content: '';
                }

                & + label:after {
                    display: block;
                    position: absolute;
                    bottom: 16px;
                    right: 16px;
                    height: 24px;
                    width: 24px;
                    background-image: url('#{$path-images}/theme-select-arrow.svg'); 
                    background-size: 16px 16px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: $white;
                    content: "";
                    border-radius: 4px;
                }
            }
        }

        label {
            display: block;
            height: 100%;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.fund-candle-select {
    .fund-candle-select-option {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        background-repeat: no-repeat;
        background-size: 88px;
        background-position: 24px center;
        padding: 20px 20px;
        z-index: 0;
        border: 1px solid #DBDBDB;
        border-radius: 10px;
        transition: .1s;

        &:hover {
            border-color: $grey-dark;

/*            &:before {
                content: ' ';
                width: 88px;
                height: 124px;
                background-color: transparent;
                background-image: url('#{$path-images}/candle-lit.gif');
                background-size: contain;
                display: block;
                background-size: 64px;
                background-repeat: no-repeat;
                background-position: 16px -8px; // center -6px;
                position: absolute;
                z-index: -1;
            }*/
        }

        &.selected {
            background-color: #FFF5EB;
            border: 2px solid #E57700;

/*            &:before {
                content: ' ';
                width: 88px;
                height: 124px;
                background-color: transparent;
                background-image: url('#{$path-images}/candle-lit.gif');
                background-size: contain;
                display: block;
                background-size: 64px;
                background-repeat: no-repeat;
                background-position: 16px -8px; // center -6px;
                position: absolute;
                z-index: -1;
            }*/
        }

        .text {
            flex: 100%;
            height: auto;
            margin: 0 0 0 34%;

            @include respond-min($medium-device) {
                margin: 0 0 0 24%;
            }

            @include respond-min($extra-large-device) {
                margin: 0 0 0 34%;
            }

            p {
                &:first-of-type {
                    font-weight: $label-font-weight;
                    margin-bottom: 8px;
                }

                &:last-of-type {
                    font-size: $small-body-font-size;
                    font-weight: normal;
                    color: $grey-dark;
                }
            }

            .candle-description {
                margin: 0;
                font-size: $small-body-font-size;
                line-height: $small-body-line-height;

                @include respond-min($small-device) {
                    font-size: $body-font-size;
                    line-height: $body-line-height;
                }
            }
        }

        label {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            &:after {
                top: 16px;
            }
        }

        input[type=radio] {
            &:checked {
                & + label:before {
                }

                & + label:after {
                    background-color: #E57700;
                    background-image: url('#{$path-images}/candle-select-arrow.svg');
                    color: #fff;
                }
            }
        }
    }
}

.fund-candle-select .fund-candle-select-option input[type=radio]:checked + label:before {
    box-shadow: none !important;
}

.field-validation-error {
    &.custom-val {
        display: none;
        transform: scale(0);
        transition: transform 1s ease-in-out;
        //we will make this transition when shown
        //note we using custom js validation to do this
        &.show-err {
            display: block;
            opacity: 1;
            transform: scale(1);
            background: #fbd2d3;
            border: 1px solid #ed1c24;
            color: #ed1c24;
            /*max-width: 520px;*/
            padding: 1em;
        }
    }
}

//e-buttons
a[class^="e-button"] {
    &.e-button_secondary {
        background: $air-force-blue;
        min-width: $button-min-width;
    }

    &.c-donations-summary__link {
        font-size: 18px;
        padding: 9px 10px;
    }
}
.c-donations-summary_wrap{
    .e-button:nth-child(n+1){
        margin-top:10px;
    }
}

//.c-donations-summary_wrap .c-donations-summary
.c-fund-information__donations {
    .c-donations-summary_wrap {
        .c-donations-summary{
            top:-85px;
            padding-top:102px;
        }
    }
}
//Donations list
.c-donations__list {
    .c-donation{
        position:relative;
        .head {
            &.candle {
                &:after {
                    content: ' ';
                    @include image-icon('#{$path-images}/donation-candle.svg', 24px, 15px);
                    position: absolute;
                    top: 24px;
                    right: 0;
                }
            }
        }
    }
}

//my account donation paging
.c-my-donations {
    .e-list{
        li{
            margin-bottom:$label-line-height;
        }
    }
    .c-my-donations__pager {
        font-size: $label-font-size;
        color: $army-red;
        margin-bottom: $label-line-height;
        text-align:center;
    }
}